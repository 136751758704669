// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_textarea__3AZes {
    height: auto;
    font-size: 100%;
    line-height: 1.5;
    margin: 0.5rem 0 0 0;
    overflow: visible;
    background-color: rgba(255,255,255,0.7);
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/textarea/Textarea.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,uCAAuC;IACvC,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".textarea {\r\n    height: auto;\r\n    font-size: 100%;\r\n    line-height: 1.5;\r\n    margin: 0.5rem 0 0 0;\r\n    overflow: visible;\r\n    background-color: rgba(255,255,255,0.7);\r\n    font-family: \"Open Sans\", sans-serif;\r\n    font-optical-sizing: auto;\r\n    font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `Textarea_textarea__3AZes`
};
export default ___CSS_LOADER_EXPORT___;
