// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_logo__hLUwo,
h1,
h2,
h3,
h4,
h5 {
    margin: 1.5rem 0;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
    line-height: 1.2;
    color: white;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child) {
    margin: 1.5rem 0;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
    margin-top: 2rem;
}

h1 {
    font-size: 1.75rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1rem;
}

@media (min-width: 600px) {
    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child) {
        margin-top: 2.5rem;
    }
    h1 {
        font-size: 2.25rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 1.25rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.module.css"],"names":[],"mappings":"AAAA;;;;;;IAMI,gBAAgB;IAChB,gBAAgB;IAChB,4FAA4F;IAC5F,gBAAgB;IAChB,YAAY;AAChB;;AAEA;;;;;IAKI,gBAAgB;AACpB;;AAEA;;;IAGI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;;;QAGI,kBAAkB;IACtB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,eAAe;IACnB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".logo,\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5 {\r\n    margin: 1.5rem 0;\r\n    font-weight: 600;\r\n    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;\r\n    line-height: 1.2;\r\n    color: white;\r\n}\r\n\r\nh1:not(:first-child),\r\nh2:not(:first-child),\r\nh3:not(:first-child),\r\nh4:not(:first-child),\r\nh5:not(:first-child) {\r\n    margin: 1.5rem 0;\r\n}\r\n\r\nh1:not(:first-child),\r\nh2:not(:first-child),\r\nh3:not(:first-child) {\r\n    margin-top: 2rem;\r\n}\r\n\r\nh1 {\r\n    font-size: 1.75rem;\r\n}\r\n\r\nh2 {\r\n    font-size: 1.5rem;\r\n}\r\n\r\nh3 {\r\n    font-size: 1.25rem;\r\n}\r\n\r\nh4 {\r\n    font-size: 1.1rem;\r\n}\r\n\r\nh5 {\r\n    font-size: 1rem;\r\n}\r\n\r\n@media (min-width: 600px) {\r\n    h1:not(:first-child),\r\n    h2:not(:first-child),\r\n    h3:not(:first-child) {\r\n        margin-top: 2.5rem;\r\n    }\r\n    h1 {\r\n        font-size: 2.25rem;\r\n    }\r\n    h2 {\r\n        font-size: 2rem;\r\n    }\r\n    h3 {\r\n        font-size: 1.75rem;\r\n    }\r\n    h4 {\r\n        font-size: 1.5rem;\r\n    }\r\n    h5 {\r\n        font-size: 1.25rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Header_logo__hLUwo`
};
export default ___CSS_LOADER_EXPORT___;
